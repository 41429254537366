import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetPassRes,
  setPassRes,
} from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
} from "../../StyledComponents";
import { Title, Input } from "../Styles";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import password_icon from "../../../Assets/images/password_setup_icon.png";
import { LoginPass } from "../../../StateManagement/Reducers/LoginState";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";

const SetupPassword = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const { state } = useLocation();
  const Navigate = useNavigate();

  const [email] = useState(state ? state.type.email : "");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [errMsg1, setErrMsg1] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [PasswordShow1, setPasswordShow1] = useState(true);

  // const backurl =
  //   localStorage.getItem("File_URL") &&
  //   Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  // const File_URL = backurl + "/documenting/";
  const File_URL =
  DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/rpm_logo.png";
  const text = useSelector((state) => state.PreLogin.logo);
  const passRes = useSelector((state) => state.PreLogin.passWordRes);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const HandleSave = (e) => {
    e.preventDefault();
    if (passErr || misMatch) return;
    let err = 0;
    if (pwd1 === "") {
      setPassErr(true);
      setErrMsg("Please enter the password");
      err = 1;
    }

    if (pwd2 === "") {
      setMissMatch(true);
      setErrMsg1("Please enter the password");
      err = 1;
    }
    if (pwd1 === pwd2 && err === 0) {
      dispatch(
        GetPassRes({
          type: { new_password: pwd1, con_password: pwd2, email: email },
        })
      );
    } else {
      setMissMatch(true);
      if (err === 0) {
        setErrMsg1("Password mismatch");
      }
    }
  };

  const PasswordCheck = (e) => {
    setPwd1(e.target.value);
    setMissMatch(false);
    setErrMsg1("");
    dispatch(setPassRes(""));
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };

  const ConformPasswordCheck = (e) => {
    setPwd2(e.target.value);
    dispatch(setPassRes(""));
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
      setErrMsg1("");
    } else {
      setMissMatch(true);
      setErrMsg1(
        "Password must contain at least 8 characters, including UPPER/lowercase, symbol and numbers "
      );
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (Object.keys(passRes).length > 0) {
      if (passRes === "Success") {
        dispatch(LoginPass({ status: "", output: "" }));
        Navigate("/Login");
      } else if (passRes === "Failure") {
        setMissMatch(true);
        setErrMsg1("passWord saving failed");
      } else if (passRes !== "") {
        setMissMatch(true);
        setErrMsg1(passRes);
      }
    }
  }, [passRes]);
  //-----------------------------UseEffect End---------------------------------------------- //

  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF">
        <Div width="100%">
          <Div width="500px" textAlign="center" margin="auto">
            {text.length !== 0 && (
              <Div textAlign="center" marginTop="72px" marginBottom="29px">
              <Image
                loading="lazy"
                src={File_URL}
                height="71px"
                width="173px"
                objectFit="contain"
              />
            </Div>
            )}
            <Image
              src={password_icon}
              alt="Setup password"
              display="block"
              margin="auto"
              textAlign="center"
              marginBottom="32px"
              width="124px"
              height="124px"
              marginTop="10%"
            ></Image>
            <Title
              color="#000000"
              fontWeight="700"
              fontSize="36px"
              lineHeight="44px"
              marginBottom="12px"
            >
              Reset Your Password
            </Title>
            <FormGroup marginBottom="32px" display="grid" width="100%">
              Your ID has been verified! Set your new password here
            </FormGroup>
            <Form paddingBottom="1px">
              <FormGroup
                marginBottom="19px"
                display="grid"
                width="100%"
                position="relative"
              >
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  color="#7D8592"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                >
                  Password
                </FormLabel>
                <Input
                  placeholder="Enter password"
                  type={PasswordShow ? "password" : "text"}
                  value={pwd1}
                  onChange={(e) => PasswordCheck(e)}
                />
                {PasswordShow ? (
                  <FaRegEye
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow((Prevstate) => !Prevstate);
                    }}
                  />
                )}
                {passErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    fontFamily="inter"
                    lineHeight="24px"
                    marginTop="5px"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup
                marginBottom="19px"
                display="grid"
                width="100%"
                position="relative"
              >
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  color="#7D8592"
                  fontWeight="600"
                  fontSize="14px"
                  lineHeight="24px"
                  letterSpacing="1px"
                >
                  Confirm Password
                </FormLabel>
                <Input
                  placeholder="Re-enter password"
                  type={PasswordShow1 ? "password" : "text"}
                  value={pwd2}
                  onChange={(e) => ConformPasswordCheck(e)}
                />
                {PasswordShow1 ? (
                  <FaRegEye
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow1((Prevstate) => !Prevstate);
                    }}
                  />
                ) : (
                  <FaRegEyeSlash
                    style={{
                      position: "absolute",
                      right: 15,
                      top: "43px",
                      cursor: "pointer",
                      color: "#7d8592",
                      fontSize: 20,
                    }}
                    onClick={() => {
                      setPasswordShow1((Prevstate) => !Prevstate);
                    }}
                  />
                )}
                {misMatch && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    fontFamily="inter"
                    lineHeight="24px"
                    marginTop="5px"
                  >
                    {errMsg1}
                  </FormLabel>
                )}
              </FormGroup>

              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  padding="13px 140px 13px 140px"
                  borderRadius="8px"
                  border="none"
                  color="#FFF"
                  onClick={(e) => HandleSave(e)}
                  cursor="pointer"
                  className="save_active"
                  hoverBackColor="#005FB2"
                >
                  Save password
                </Button>
              </FormGroup>
            </Form>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default SetupPassword;
