import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import {
  GetEmailRes,
  setMode,
} from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Text,
} from "../../StyledComponents";
import { Input, Title } from "../Styles";

import MailImage from "../../../Assets/images/mailPortal.png";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import AlertModal from "../../StyledComponents/AlertModal/AlertModal";
import DBHost from "../../../Config/DBHost";

const InstantSignIn = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  let Mode = useSelector((state) => state.PreLogin.mode);
  const text = useSelector((state) => state.PreLogin.logo);
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [status, setStatus] = useState("");
  const emailResponse = useSelector((state) => state.PreLogin.EmailResp);

  // const backurl =
  //   localStorage.getItem("File_URL") &&
  //   Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  const File_URL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/rpm_logo.png";
  // const File_URL = backurl + "/documenting/";
  const mode =
    localStorage.getItem("Mode") &&
    Decrypt_Value(localStorage.getItem("Mode"), "rpmportal"); 
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const HandleSubmit = (e) => {
    e.preventDefault();

    if (mode === "old") {
      Mode = "old";
    }

    if (email !== "") {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (regex.test(email) == true) {
        setErrMsg("");
        dispatch(setMode(mode));
        dispatch(GetEmailRes({ type: { email: email }, mode: Mode }));
        setModalAlerShow(true);
        setStatus("success");
        setModalMsg("Your details are being verified.");
      } else {
        setMailErr(true);
        setErrMsg("Invalid Email");
      }
    } else {
      setMailErr(true);
      setErrMsg("Please Enter the Email Address");
    }
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source');
    if (source === 'email') {
      let mode = "old";
        console.log('User came from email');
        localStorage.setItem("Mode", Encrypt_Value(mode, "rpmportal"));
    } 
}, [window.location]);


  useEffect(() => {
    if (emailResponse.status === "Success") {
      setModalAlerShow(false);
      if (emailResponse.output === "Account Already Found!") {
        if (Mode === "new") {
          Navigate("/InstantSignIn");
        } else {
          Navigate("/Login");
        }
      } else {
        Navigate("/VerifyAccount", {
          state: { type: { email } },
        });
      }
    } else if (emailResponse.status === "Failure") {
      setMailErr(true);
      setModalAlerShow(false);
      if (Mode === "old") {
        if (emailResponse.output === "Unauthorised") {
          setErrMsg("Access Restricted Contact Service Provider");
        } else {
          setErrMsg("Please Enter Registered Email");
        }
      } else if (Mode === "new") {
        setErrMsg("This Email already exists");
      }
    }
  }, [emailResponse]);

  useEffect(() => {
    setErrMsg("");
  }, []);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <>
      <Div display="flex" height="100vh" minWidth="1024px">
        <Div width="50%" backgroundColor="#F8FAFB" position="relative">
          <LoginLeft />
        </Div>
        <Div width="50%" backgroundColor="#FFFFFF">
          <Div width="400px" textAlign="center" margin="auto">
            {text.length !== 0 && (
              <Div textAlign="center" marginTop="72px" marginBottom="29px">
                <Image
                  loading="lazy"
                  src={File_URL}
                  height="71px"
                  width="173px"
                  objectFit="contain"
                />
              </Div>
            )}
            <Image
              src={MailImage}
              alt="Email Verified"
              width="142px"
              height="112px"
              display="block"
              margin="auto"
              textAlign="center"
              marginBottom="32px"
              marginTop="10%"
            />
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="32px"
              marginBottom="12px"
            >
              Enter Email
            </Title>
            <Text
              fontSize="20px"
              lineHeight="27px"
              alignItems="center"
              color=" #667085"
              marginBottom="32px"
              textAlign="center"
            >
              Enter email id to get your verification code
            </Text>
            <Form>
              <FormGroup marginBottom="41px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  fontSize="14px"
                  color="#7D8592"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Email address
                </FormLabel>
                <Input
                  color="#7D8592"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => EmailCheck(e)}
                  id="sig_emil-inp_ent"
                />
                {mailErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  padding="13px 140px 13px 140px"
                  borderRadius="8px"
                  border="none"
                  color="#FFF"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  onClick={(e) => HandleSubmit(e)}
                  id="sig_sub-btn_cnt"
                  cursor="pointer"
                  className="save_active"
                  hoverBackColor="#005FB2"
                >
                  Continue
                </Button>
              </FormGroup>
            </Form>
          </Div>
        </Div>
      </Div>
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default InstantSignIn;
