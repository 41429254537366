import React, { useEffect, useRef, useState } from "react";
import { ChatBox, ChatBoxMsg, MessageContainer } from "./Styles";
import { Avatar, AvatarLetter, AvatarImage, Images } from "../StyledComponents";
import {
  Div,
  Input,
  Modal,
  ModalContainer,
  Text,
} from "../../StyledComponents";
import moment from "moment";
import DocType from "../../../Assets/images/docType.png";
import DBHost from "../../../Config/DBHost";

const ChatBody = ({
  data,
  handlePreview,
  setViewType,
  setSelectedFile,
  isValidFileUploaded,
  hightlightText,
  navigateSearch,
  steps,
  steps1,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const inputEl = useRef(null);
  const messageEl = useRef(null);
  const highLightEl = useRef([]);
  const [lp, setLp] = useState(0);
  const [drop, setDrop] = useState(false);
  const [fileErr, setFileErr] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const audioURL =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/Voice_recordings/";
  const imageUrl =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/MessageImages/";
  const File_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";
  const FileURL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";

  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const handleDrag = (e) => {
    setFileErr(false);
    console.log(e, "eeee draggg");
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrop(true);
    } else if (e.type === "dragleave") {
      setDrop(false);
    }
  };

  const handleDrop = (e) => {
    console.log(e, " drop what is that");
    e.preventDefault();
    e.stopPropagation();
    const valid = e.dataTransfer.files[0].type.split("/")[1];

    if (
      valid === "pdf" ||
      valid === "png" ||
      valid === "jpeg" ||
      valid === "jpg"
    ) {
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        if (isValidFileUploaded(file)) {
          setSelectedFile(file);
        }
        setDrop(false);
        setFileErr(false);
      }
    } else {
      setFileErr(true);
      setTimeout(() => {
        setFileErr(false);
        setDrop(false);
      }, 1500);
    }
  };

  const handleSearchScrolling = () => {
    if (highLightEl.current.length > 0) {
      if (navigateSearch === "up") {
        if (lp < 0) {
          return;
        }
        if (lp >= 0) {
          let point = lp - 1;

          highLightEl.current[point].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });

          if (lp - 1 < 0) {
            return;
          }
          setLp(lp - 1);
        }
      } else if (navigateSearch === "down") {
        if (lp > highLightEl.current.length - 1) {
          return;
        }
        if (lp < highLightEl.current.length) {
          let point = lp + 1;

          highLightEl.current[point].scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });

          if (lp + 1 > highLightEl.current.length - 1) {
            return;
          }
          setLp(lp + 1);
        }
      } else {
        highLightEl.current[highLightEl.current.length - 1].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });

        setLp(highLightEl.current.length - 1);
      }
    }
  };

  const hadRef = (el, array) => {
    let enter = false;
    for (const row of array) {
      if (row.includes(hightlightText)) {
        if (el && !highLightEl.current.includes(el)) {
          highLightEl.current.push(el);
          enter = true;
          break;
        }
      } else if (el && highLightEl.current.includes(el)) {
        highLightEl.current.pop(el);
      }
    }
    if (!enter) {
      if (highLightEl.current.includes(el)) {
        highLightEl.current.pop(el);
      }
    }
  };

  const handleMessage = (msg) => {
    let arr = msg.split(" ");
    if (hightlightText !== "" && hightlightText !== undefined) {
      return (
        <span ref={(el) => hadRef(el, arr)}>
          {arr.map((row, i) =>
            row.includes(hightlightText) ? (
              <>
                <mark
                  key={i}
                  // ref={(el) => hadRef1(el, arr)}
                >
                  {`${row} `}
                </mark>
                {/* &nbsp; */}
              </>
            ) : (
              <>
                <span key={i}> {`${row} `}</span>
              </>
            )
          )}
        </span>
      );
    } else {
      return (
        <>
          <span>
            {arr.map((row, i) => (
              <>
                {/* don't remove the space */}
                <span key={i}>{row} </span>
                {/* don't remove the space */}
              </>
            ))}
          </span>
        </>
      );
    }
  };

  // ------------------------------Functions End-------------------------- //
  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    setTimeout(() => {
      messageEl.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 100);
  }, [data, hightlightText]);

  useEffect(() => {
    setLp(highLightEl.current.length - 1);
  }, [hightlightText]);

  useEffect(() => {
    if (
      hightlightText !== "" &&
      hightlightText !== undefined &&
      highLightEl.current.length > 0
    ) {
      setTimeout(() => {
        handleSearchScrolling();
      }, 100);
    }
  }, [steps, steps1, hightlightText, highLightEl.current.length]);

  // ------------------------------UseEffect End-------------------------- //

  return (
    <>
      <MessageContainer
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        drop={drop}
        fileErr={fileErr}
      >
        <Input
          display="none"
          ref={inputEl}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          // accept=".jpg, .jpeg, .png, .pdf, .gif"
          accept=".pdf,.png,.jpg,.bmp,.jpeg"
        />

        {data.length !== 0 ? (
          data.map((item, index) => {
            return (
              <ChatBox
                user={item.sender === "Patient" ? true : false}
                type={item.type}
                key={`${index}msg`}
              >
                {item.type !== "date" && item.sender !== "Patient" && (
                  <>
                    {item.prov_image === null ? (
                      <Avatar width="32px" height="32px">
                        <AvatarLetter top="8px" left="9px">
                          {item.prov_name.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <AvatarImage
                        src={File_URL + item.prov_image}
                        alt="image-failed"
                        loading="lazy"
                        width="32px"
                        height="32px"
                      />
                    )}
                  </>
                )}

                <ChatBoxMsg
                  user={item.sender === "Patient" ? true : false}
                  type={item.type}
                  providerwidth={item.sender === "Provider" ? true : false}
                >
                  <Text
                    fontFamily="open Sans,sans-serif"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="22px"
                    color="rgba(46, 46, 46, 0.50)"
                  >
                    {item.type === "date" &&
                      moment(item.date).calendar(null, {
                        sameDay: "[Today]",
                        nextDay: "[Tomorrow]",
                        nextWeek: "dddd",
                        lastDay: "[Yesterday]",
                        lastWeek: "dddd, MMMM D",
                        sameElse: "dddd, MMMM Do",
                      })}
                  </Text>

                  {item.type === "text" && (
                    <>
                      <Div display="flex">
                        <Text
                          width="unset"
                          fontFamily="Inter"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="justify"
                          color="unset"
                          whiteSpace="normal"
                          wordBreak="break-all"
                          display="inline"
                        >
                          {handleMessage(item.message)}
                        </Text>
                        <Text
                          width="unset"
                          margin="auto 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </Div>
                    </>
                  )}

                  {item.type === "audio" && (
                    <>
                      <Div display="flex">
                        <audio
                          controls
                          src={audioURL + item.imageUrl}
                          style={{ height: "40px", width: "230px" }}
                        />
                        <Text
                          fontFamily="'Inter',sab-serif"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="right"
                          color="unset"
                          display="inline"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          margin="auto 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </Div>
                    </>
                  )}

                  {item.type === "image" && (
                    <>
                      <Div display="flex">
                        <Images
                          cursor="pointer"
                          MaxWidth="221px"
                          MaxHeight="137px"
                          objectFit="cover"
                          display="block"
                          src={item.imageUrl && imageUrl + item.imageUrl}
                          alt=" "
                          onClick={() => {
                            handlePreview(imageUrl + item.imageUrl);
                            setViewType("image");
                          }}
                        />
                        <Text
                          fontFamily="'Inter',sab-serif"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="right"
                          color="unset"
                          display="inline"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          margin="auto 0 0 10px"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </Div>
                    </>
                  )}
                  {item.type === "doc" && (
                    <>
                      <Div display="flex">
                        <Images
                          cursor="pointer"
                          MaxWidth="221px"
                          MaxHeight="137px"
                          objectFit="cover"
                          display="block"
                          src={DocType}
                          alt=" "
                          onClick={() => {
                            handlePreview(imageUrl + item.imageUrl);
                            setViewType("doc");
                          }}
                        />
                        <Text
                          fontFamily="Inter"
                          fontWeight="500"
                          fontSize="14px"
                          lineHeight="17px"
                          textAlign="unset"
                          color="unset"
                          display="inline"
                        >
                          {item.message}
                        </Text>
                        <Text
                          width="unset"
                          fontWeight="400"
                          fontSize="12px"
                          lineHeight="12px"
                          color={
                            item.sender === "Patient" ? "#EDEDED" : "#BAC0C6"
                          }
                          display="inline"
                          textAlign="right"
                          verticalAlign="sub"
                          margin="auto 0 0 10px"
                        >
                          {item.date && moment(item.date).format("h:mm a")}
                        </Text>
                      </Div>
                    </>
                  )}
                </ChatBoxMsg>
                {item.type !== "date" && item.sender === "Patient" && (
                  <>
                    {item.pat_image === null ? (
                      <Avatar width="32px" height="32px" minWidth="32px">
                        <AvatarLetter top="8px" left="9px">
                          {item.pat_name.charAt(0).toUpperCase()}
                        </AvatarLetter>
                      </Avatar>
                    ) : (
                      <AvatarImage
                        src={FileURL + item.pat_image}
                        alt="image-failed"
                        loading="lazy"
                        width="32px"
                        height="32px"
                        minWidth="32px"
                        objectFit="unset"
                      />
                    )}
                  </>
                )}
              </ChatBox>
            );
          })
        ) : (
          <Div
            display="flex"
            justifyContent="center"
            fontWeight="500"
            fontSize="14px"
            lineHeight="17px"
            color="#8A969F"
            backColor="unset"
          >
            No Messages....
          </Div>
        )}
        <Div className="test" ref={messageEl}></Div>
      </MessageContainer>

      {fileErr && (
        <Modal
          show="flex"
          justifyContent="center"
          // style={{
          //   width: screenSize.width * 1.0,
          //   height: screenSize.height * 1.0,
          // }}
        >
          <ModalContainer
            justifyContent="center"
            alignItems="center"
            top="40%"
            borderRadius="8px"
            backgroundColor="#f8d7da"
            border="1px solid transparent"
            borderColor="#f5c6cb"
          >
            <Div
              width={""}
              padding="20px 21px 20px 22px"
              display="flex"
              alignItems="center"
            >
              <Text
                fontFamily="'Open Sans', sans-serif"
                fontWeight="600"
                fontSize="16px"
                color="rgba(176, 0, 32, 1)"
                padding="4px 6px 4px 16px"
                maxWidth="500px"
              >
                Please Select Valid File Format
              </Text>
            </Div>
          </ModalContainer>
        </Modal>
      )}
    </>
  );
};

export default ChatBody;
