import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Div,
  PageSubTitle,
  Button,
  Table,
  TableRow,
  TableHead,
  TableHeadCell,
  TableBody,
  TableBodyCell,
  Span,
  Image,
  Text,
} from "../StyledComponents";
import { DropdownContent, DropdownList, Iframe, LinkA } from "./Styles";
import { FaPlus } from "react-icons/fa";
import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import UploadDocument from "./UploadDocument";
import pencil from "../../Assets/images/pencil.png";
import Download from "../../Assets/images/Download.png";
import Delete from "../../Assets/images/Delete.png";
import forward from "../../Assets/images/forward.png";
import printer from "../../Assets/images/printer.png";
import Pagination from "../StyledComponents/Pagination";
import { useReactToPrint } from "react-to-print";
import {
  getDocumentslist,
  setInsertEmptyResp,
  getUpdateDocumentslist,
  setUpdateDocEmptyResp,
  getPreviewDocument,
  getPreviewDownload,
  getDeleteTempDoc,
  getDeleteDocumentslist,
  setDocsLoad,
} from "../../StateManagement/Reducers/DocumentsState";
import TopNav from "../TopNav";
import CreateSignature from "./CreateSignature";
import AlertModal from "../StyledComponents/AlertModal/AlertModal";
import EmptyData from "../StyledComponents/EmptyData";
import { Spinner, SpinnerWrapper } from "../Vitals/styles";
import Logo from "../../Assets/images/Logo.png";
import DBHost from "../../Config/DBHost";
const DocumentList = () => {
  // ------------------------------ State And Variables Start------------------------ //
  const [isupload, setIsUpload] = useState(false);
  const [editopen, setEditopen] = useState(false);
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [status, setStatus] = useState("");
  const [forprint, setforprint] = useState("");
  const dispatch = useDispatch();
  const componentRef = useRef();

  const FileURLProfile =
    DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";

  const FileURL = DBHost.finalurl + "/src/Services/documentimg/";
  console.log(FileURL, "FileURL");
  const DocumentsList = useSelector((state) => state.Document.Documentlist);
  const InsertMsg = useSelector((state) => state.Document.DocumentInsert);
  const DocUpdateMsg = useSelector((state) => state.Document.DocUpdate);
  const PreviewDownload = useSelector(
    (state) => state.Document.previewdownload
  );
  const DocumentDownloadUrl = useSelector(
    (state) => state.Document.DownloadDocumentlist
  );

  const DocumentDelete = useSelector(
    (state) => state.Document.DeleteDocumentlist
  );

  const SignInsert = useSelector((state) => state.Document.SignInsert);
  const Loading = useSelector((state) => state.Document.LoadingDocs);

  const [currentPage, setCurrentPage] = useState(DocumentsList?.currentPage);
  const [currentPosts, setCurrentPost] = useState([]);
  const [howManyPages, setHowManyPages] = useState(0);
  const [iscreate, setIsCreate] = useState();
  const [tosign, settosign] = useState("");
  const [donwloadprintid, setdonwloadprintid] = useState("");

  // ------------------------------ State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //

  const setEditAction = (ind) => {
    if (editopen === ind) {
      setEditopen("");
    } else {
      setEditopen(ind);
    }
  };

  const handleClick = (e) => {
    if (!e.target.id) {
      setEditopen("");
    }
  };

  const HanldeCloseSignature = () => {
    setIsCreate(false);
    settosign("");
  };

  const HanldeSignature = (ind, id, status) => {
    if (status === "In Review") {
      setEditAction(ind);
      setModalAlerShow(true);
      setModalMsg("This Document Already Signatured!.");
      setStatus("failure");
      setTimeout(() => {
        InitialDispatch();
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        dispatch(setUpdateDocEmptyResp());
      }, 2000);
    } else {
      settosign(id);
      setIsCreate(true);
      setEditAction(ind);
    }
  };

  const SentToReview = (ind, id, status) => {
    if (status === "In Review") {
      setModalAlerShow(true);
      setModalMsg("This Document Already Sent to Review");
      setStatus("failure");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
      }, 2000);

      setEditAction(ind);
    } else if (status === "Locked") {
      setModalAlerShow(true);
      setModalMsg("This Document is Already Locked");
      setStatus("failure");
      setTimeout(() => {
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
      }, 2000);
      setEditAction(ind);
    } else {
      dispatch(getUpdateDocumentslist({ ids: id }));
      setEditAction(ind);
    }
  };

  const PreviewDocument = (id, fileurl, print, ind) => {
    setdonwloadprintid(id);
    if (print === "yes") {
      setEditAction(ind);
      setforprint("yes");
    }
    dispatch(getPreviewDocument({ file: fileurl, id: id }));
  };

  const FileDownload = async (fileurl, id, ind) => {
    setdonwloadprintid(id);
    dispatch(getPreviewDownload({ file: fileurl, id: id, testurl: FileURL }));
    setEditAction(ind);
  };

  const getFileExtension = (filename) => {
    const extension = filename.split(".").pop();
    return extension;
  };

  const HandlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const HandleDelete = (ind, id) => {
    if (window.confirm("Are you sure you want to Delete?")) {
      dispatch(getDeleteDocumentslist({ ids: id }));

      setEditAction(ind);
    }
  };

  const InitialDispatch = () => {
    dispatch(
      getDocumentslist({
        filtertype: "All Documents",
        page: 1,
        pageSize: 10,
      })
    );
  };

  // ------------------------------Functions End------------------------------- //

  // ------------------------------UseEffect Start-------------------------- //

  useEffect(() => {
    if (PreviewDownload !== "") {
      var tag = document.createElement("a");
      tag.href = PreviewDownload.file;
      tag.download = PreviewDownload.filename;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      dispatch(getDeleteTempDoc({ id: donwloadprintid }));
      setdonwloadprintid("");
    }
  }, [PreviewDownload]);

  useEffect(() => {
    if (currentPage >= 1) {
      dispatch(
        getDocumentslist({
          filtertype: "All Documents",
          page: currentPage,
          pageSize: 10,
        })
      );
    }
  }, [currentPage]);

  useEffect(() => {
    if (DocumentsList) {
      setCurrentPost(DocumentsList.data ?? []);
      setHowManyPages(DocumentsList.totalPages ?? 0);
    }
  }, [DocumentsList]);

  useEffect(() => {
    if (DocUpdateMsg === "success") {
      setModalAlerShow(true);
      setModalMsg("Document Sent to Review Successfully");
      setStatus("success");
      setTimeout(() => {
        InitialDispatch();
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        dispatch(setUpdateDocEmptyResp());
      }, 2000);
    } else if (DocumentDelete === "success") {
      setModalAlerShow(true);
      setModalMsg("Document Deleted Successfully");
      setStatus("success");
      setTimeout(() => {
        InitialDispatch();
        setCurrentPage(1);
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        dispatch(setUpdateDocEmptyResp());
      }, 2000);
    } else if (InsertMsg === "file added") {
      setModalAlerShow(true);
      setModalMsg("Document Upload Successfully");
      setStatus("success");
      setTimeout(() => {
        InitialDispatch();
        setCurrentPage(1);
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        dispatch(setInsertEmptyResp());
      }, 2000);
    } else if (SignInsert === "success") {
      setModalAlerShow(true);
      setModalMsg("Document Signed Successfully");
      setStatus("success");
      setTimeout(() => {
        InitialDispatch();
        setModalAlerShow(false);
        setModalMsg("");
        setStatus("");
        dispatch(setUpdateDocEmptyResp());
      }, 2000);
    }
  }, [DocUpdateMsg, DocumentDelete, InsertMsg, SignInsert]);

  useEffect(() => {
    if (DocumentDownloadUrl !== "") {
      if (forprint === "yes") {
        const fileextension = getFileExtension(DocumentDownloadUrl);
        if (
          fileextension === "png" ||
          fileextension === "txt" ||
          fileextension === "jpg" ||
          fileextension === "jpeg" ||
          fileextension === "PNG" ||
          fileextension === "TXT" ||
          fileextension === "JPG" ||
          fileextension === "JPEG"
        ) {
          HandlePrint();
        } else if (fileextension === "pdf" || fileextension === "PDF") {
          document.getElementById("preview_document").click();
        } else {
          window.alert("File Not Allow to Print.");
        }
        setforprint("");
      } else {
        document.getElementById("preview_document").click();
      }
      setTimeout(() =>
        dispatch(getDeleteTempDoc({ id: donwloadprintid }), 5000)
      );
    }
  }, [DocumentDownloadUrl]);

  useEffect(() => {
    dispatch(setDocsLoad());
    InitialDispatch();
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  // ------------------------------UseEffect End-------------------------- //
  return (
    <>
      <Div>
        <TopNav title="Document" />
        {Loading ? (
          <>
            <SpinnerWrapper>
              <Spinner></Spinner>
              <Image
                width="40px"
                height="40px"
                position="absolute"
                src={Logo}
              />
            </SpinnerWrapper>
          </>
        ) : (
          <>
            <Div
              display="flex"
              justifyContent="space-between"
              padding="4px 40px 18px 40px"
            >
              <Div display="flex" alignItems="center">
                <PageSubTitle color="#2E2E2E" fontWeight="400" fontSize="20px">
                  Document list
                </PageSubTitle>
              </Div>
              <Button
                color="#fff"
                background="#2C7BE5"
                border="none"
                borderRadius="8px"
                padding="12px 20px"
                fontSize="16px"
                cursor="pointer"
                onClick={() => {
                  setIsUpload(true);
                }}
                display="flex"
                svgmarginRight="10px"
                svgfontSize="16px"
                justifyContent="center"
                alignItems="center"
                height="40px"
                className="save_active"
                hoverBackColor="#005FB2"
              >
                <FaPlus />
                Upload Document
              </Button>
            </Div>

            <Iframe
              seamless="seamless"
              width="100%"
              height="800px"
              ref={componentRef}
              src={FileURL + DocumentDownloadUrl}
            />
            <LinkA
              href={FileURL + DocumentDownloadUrl}
              target="_blank"
              id="preview_document"
            />
            <Table>
              <TableHead background="#F7F7F7">
                <TableRow>
                  <TableHeadCell>Document</TableHeadCell>
                  <TableHeadCell>Created Date</TableHeadCell>
                  <TableHeadCell>Share By</TableHeadCell>
                  <TableHeadCell colSpan={5} textAlign="center">
                    Status
                  </TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPosts.length > 0 ? (
                  <>
                    {currentPosts.map((row, ind) => {
                      return (
                        <>
                          <TableRow index={ind}>
                            <TableBodyCell
                              maxWidth="300px"
                              title={row.form_name}
                              overflow="hidden"
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                            >
                              {row.form_name}
                            </TableBodyCell>
                            <TableBodyCell>{row.date}</TableBodyCell>
                            <TableBodyCell
                              display="flex"
                              alignItems="center"
                              width="max-content"
                            >
                              {row.prov_img_url !== null ? (
                                <Image
                                  objectFit="cover"
                                  loading="lazy"
                                  width="28px"
                                  height="28px"
                                  borderRadius="50%"
                                  margin="0 12px 0 0"
                                  src={FileURLProfile + row.prov_img_url}
                                />
                              ) : (
                                <Div
                                  width="28px"
                                  height="28px"
                                  borderRadius="50%"
                                  background="rgb(168, 174, 190)"
                                  display="flex"
                                  justifyContent="center"
                                  textAlign="center"
                                  color="#FFFFFF"
                                  alignItems="center"
                                  margin="0 12px 0 0"
                                >
                                  <Span fontSize="20px">
                                    {row.username !== undefined &&
                                      row.username.charAt(0).toUpperCase()}
                                  </Span>
                                </Div>
                              )}
                              {row.username}
                            </TableBodyCell>
                            <TableBodyCell padding="16px 0px 10px 38px;">
                              {row.status === "In Review" && (
                                <Div
                                  height="24px"
                                  float="right"
                                  textAlign="center"
                                  borderRadius="4px"
                                  paddingTop="1px"
                                  width="80px"
                                  background="rgba(32, 201, 172, 0.1)"
                                >
                                  <Text
                                    height="18px"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="18px"
                                    marginTop="2px"
                                    color="#20c9ac"
                                  >
                                    {row.status}
                                  </Text>
                                </Div>
                              )}
                              {row.status === "New" && (
                                <Div
                                  height="24px"
                                  float="right"
                                  textAlign="center"
                                  borderRadius="4px"
                                  paddingTop="1px"
                                  width="50px"
                                  background=" rgba(0, 165, 255, 0.1)"
                                >
                                  <Text
                                    height="18px"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="18px"
                                    marginTop="2px"
                                    color="#00a5ff"
                                  >
                                    {row.status}
                                  </Text>
                                </Div>
                              )}
                              {row.status === "Locked" && (
                                <Div
                                  height="24px"
                                  float="right"
                                  textAlign="center"
                                  borderRadius="4px"
                                  paddingTop="1px"
                                  width="80px"
                                  background="rgba(132, 129, 138, 0.1)"
                                >
                                  <Text
                                    height="18px"
                                    fontWeight="600"
                                    fontSize="12px"
                                    lineHeight="18px"
                                    marginTop="2px"
                                    color="#2e2c34"
                                  >
                                    {row.status}
                                  </Text>
                                </Div>
                              )}
                            </TableBodyCell>
                            <TableBodyCell onClick={() => setEditAction(ind)}>
                              <BsThreeDots
                                id="icon"
                                style={{
                                  fontSize: 24,
                                  color: "#212121",
                                  cursor: "pointer",
                                }}
                              />
                              <DropdownContent
                                overflow="hidden"
                                zIndex="999"
                                position="absolute"
                                borderRadius="6px"
                                background="#ffffff"
                                marginLeft="-157px"
                                marginTop="4px"
                                width="179px"
                                marginRight="16px"
                                boxShadow="0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05)"
                                display={editopen === ind ? "block" : "none"}
                                share={row.is_share}
                              >
                                {row.is_share !== "yes" && (
                                  <>
                                    {row.status !== "Locked" && (
                                      <DropdownList
                                        height=" 36px"
                                        cursor="pointer"
                                        flexCenterVertical
                                        id="list"
                                        padding="0px 0px 3px 20px"
                                        onClick={() =>
                                          SentToReview(ind, row.id, row.status)
                                        }
                                      >
                                        <Image
                                          objectFit="cover"
                                          width="16px"
                                          height="16px"
                                          margin="0px 12px 0px 0px"
                                          src={forward}
                                          id="doc_rvw-snt_img"
                                        />
                                        Sent to Review
                                      </DropdownList>
                                    )}
                                  </>
                                )}
                                {row.is_share === "yes" && (
                                  <>
                                    {row.req_pat_sign === "1" && (
                                      <>
                                        {row.status !== "Locked" && (
                                          <DropdownList
                                            height=" 36px"
                                            cursor="pointer"
                                            flexCenterVertical
                                            id="list1"
                                            padding="0px 0px 3px 20px"
                                            onClick={() =>
                                              HanldeSignature(
                                                ind,
                                                row.id,
                                                row.status
                                              )
                                            }
                                          >
                                            <Image
                                              objectFit="cover"
                                              width="16px"
                                              height="16px"
                                              margin="0px 12px 0px 0px"
                                              src={pencil}
                                            />
                                            Signature
                                          </DropdownList>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                <DropdownList
                                  height=" 36px"
                                  cursor="pointer"
                                  flexCenterVertical
                                  id="list2"
                                  padding="0px 0px 3px 20px"
                                  onClick={() =>
                                    PreviewDocument(
                                      row.id,
                                      row.file_url,
                                      "yes",
                                      ind
                                    )
                                  }
                                >
                                  <Image
                                    loading="lazy"
                                    width="16px"
                                    height="16px"
                                    margin="0px 12px 0px 0px"
                                    src={printer}
                                  />
                                  Print
                                </DropdownList>
                                <DropdownList
                                  height=" 36px"
                                  cursor="pointer"
                                  flexCenterVertical
                                  id="list3"
                                  padding="0px 0px 3px 20px"
                                  onClick={() =>
                                    FileDownload(row.file_url, row.id, ind)
                                  }
                                >
                                  <Image
                                    loading="lazy"
                                    width="16px"
                                    height="16px"
                                    margin="0px 12px 0px 0px"
                                    src={Download}
                                  />
                                  Download
                                </DropdownList>
                                {row.status !== "Locked" && (
                                  <DropdownList
                                    height=" 36px"
                                    cursor="pointer"
                                    flexCenterVertical
                                    id="list4"
                                    padding="0px 0px 3px 20px"
                                    onClick={() => HandleDelete(ind, row.id)}
                                  >
                                    <Image
                                      loading="lazy"
                                      width="16px"
                                      height="16px"
                                      margin="0px 12px 0px 0px"
                                      src={Delete}
                                    />
                                    Delete
                                  </DropdownList>
                                )}
                              </DropdownContent>
                            </TableBodyCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </>
                ) : (
                  <TableRow background="#FFFFF">
                    <TableBodyCell colSpan={5} textAlign="center">
                      <EmptyData />
                    </TableBodyCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </>
        )}
      </Div>
      <UploadDocument
        show={isupload}
        close={() => {
          setIsUpload(false);
        }}
      />

      <CreateSignature
        show={iscreate}
        close={HanldeCloseSignature}
        ids={tosign}
      />
      {currentPosts.length === 0 || howManyPages === 1 ? (
        ""
      ) : (
        <Pagination
          pages={howManyPages}
          setCurrentPage={setCurrentPage}
          view={currentPage}
        />
      )}
      {ModalAlerShow && (
        <AlertModal show={ModalAlerShow} msg={modalMsg} status={status} />
      )}
    </>
  );
};

export default DocumentList;
