import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { GetSinglePatDetail } from "../../StateManagement/Reducers/DashboardState";
import { Image, LinK, Text, Div, PageTitle } from "../StyledComponents";
import {
  MessageContainer,
  ProfileBox,
  DropdownContent,
  DropdownList,
  FixedTop,
  StyledLink,
  MyProfileIcon,
  LogoutIcon,
  RedNotify,
} from "./Styles";

import { GrDown } from "react-icons/gr";
import SendIcon from "../../Assets/images/Shape.png";
import axiosInst from "../../Config";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
import { GetMsgList } from "../../StateManagement/Reducers/MessengerState";
import DBHost  from '../../Config/DBHost'
const TopNav = ({ title, TitleDescription }) => {
  // ------------------------------ State and Variables Start--------------------------------------------------- //
  const inputRef = useRef(null);

  const [drop, setDrop] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [topFixed, setTopFixed] = useState(false);
  const dispatch = useDispatch();
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);
  const userList = useSelector((state) => state.Messenger.ProviderList);
  const [unreadIS, setUnreadIS] = useState(false);
  const DualColorPages = ["Messenger"];
  const location = useLocation();
  const pid =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

    const FileURL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/documenting/";



  // ------------------------------ State and Variables End--------------------------------------------------- //

  // ------------------------------ Functions Start------------------------------------------------------------- //

  const Logout = () => {
    const data = {
      pid: pid,
    };
    axiosInst
      .post(`/rpmportal/updatePortalLogout`, data)
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        window.location.href = "/";
      });
  };

  const handleScroll = () => {
    const offset = window.scrollY;

    if (
      offset > 50 ||
      (title === "Edit Profile" && offset > 15) ||
      (title === "Dashboard" && offset > 40)
    ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setDrop(false);
    }
  };

  // ------------------------------ Functions End------------------------------------------------------------- //

  // ------------------------------ useEffect Start------------------------------------------------------------- //

  useEffect(() => {
    dispatch(GetSinglePatDetail());
    dispatch({ type: "LoginUpdateCall" });
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mouseup", handleClick);

    // if (DualColorPages.includes(title)) setTopFixed(true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mouseup", handleClick);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  useEffect(() => {
    dispatch(GetMsgList());
  }, []);

  useEffect(() => {
    for (let i = 0; i < userList.length; i++) {
      if (userList[i]["unreadCount"] !== "0") {
        setUnreadIS(true);
        break;
      } else {
        setUnreadIS(false);
      }
    }

    return () => {};
  }, [userList]);
  // ------------------------------ useEffect End------------------------------------------------------------- //

  return (
    <FixedTop
      padding={
        title === "Messenger" ? "20px 40px 20px 24px" : "20px 40px 20px 40px"
      }
      alignItems={title === "Messenger" ? "baseline" : "center"}
      minWidth="650px"
      scrolled={scrolled}
      DualColor={topFixed}
    >
      <Div alignItems="left" display="flex" flexDirection="column">
        <PageTitle fontSize="32px" fontWeight="700">
          {title}
        </PageTitle>
        {title === "Messenger" && (
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="19px"
            color="#7d8592"
            textAlign="left"
            margin="8px auto 0px 0px"
          >
            {TitleDescription}
          </Text>
        )}
      </Div>
      <Div display="flex" zIndex="1001" alignItems="center">
        <StyledLink to="/messenger" exact="true">
          <MessageContainer DualColor={topFixed}>
            <Image loading="lazy" src={SendIcon} height="20px" width="20px" />
            {unreadIS && <RedNotify />}
          </MessageContainer>
        </StyledLink>
        <ProfileBox
          ref={inputRef}
          onClick={() => setDrop(!drop)}
          DualColor={topFixed}
        >
          {SingPatDetails.img_url && SingPatDetails.img_url !== null ? (
            <Image
              loading="lazy"
              height="32px"
              width="32px"
              borderRadius="50%"
              src={FileURL + SingPatDetails.img_url}
            />
          ) : (
            <Div
              height="32px"
              width="32px"
              borderRadius="50%"
              background="#A8AEBE"
              color="white"
              flexCenterAll
              margin="0 8px 0 0"
              fontSize="16px"
              fontFamily="'Work Sans',sans-serif"
              textShadow="0px 1px 0px rgba(0, 0, 0, 0.15)"
            >
              {SingPatDetails.fname &&
                SingPatDetails.fname.charAt(0).toUpperCase()}
            </Div>
          )}
          {SingPatDetails.patname}
          <GrDown />
          <DropdownContent display={drop.toString()}>
            <LinK to="/settings/editProfile">
              <DropdownList padding=" 15px 10px 12.5px 19px">
                <MyProfileIcon />
                My Profile
              </DropdownList>
            </LinK>
            <DropdownList padding=" 12.5px 10px 17px 20px" onClick={Logout}>
              <LogoutIcon />
              Logout
            </DropdownList>
          </DropdownContent>
        </ProfileBox>
      </Div>
    </FixedTop>
  );
};

export default TopNav;
