import React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { InputBox } from "../Styles";
import { Div, Text, Button, Image } from "../../StyledComponents";
import { Avatar, AvatarLetter, AvatarImage } from "../StyledComponents";
import { debounce } from "../index";
import { useCallback } from "react";
import mobilecall from "../../../Assets/images/mobilecall.png";
import callVideo from "../../../Assets/images/callvideo.png";
import DBHost from "../../../Config/DBHost";
const HeadNav = ({
  status,
  data,
  handleChatSearch,
  handleUpward,
  handleDownward,
  togg,
  handleTogg,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const [searchText, setsearchText] = useState("");

  const File_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";


  // ------------------------------State And Variables End------------------------ //

  // ------------------------------Functions Start------------------------------- //
  const handleToggle = () => {
    setsearchText("");
    handleTogg();
  };

  const handleTextSearch = (e) => {
    setsearchText(e.target.value);
    optimizedFn(e.target.value);
  };

  const optimizedFn = useCallback(debounce(handleChatSearch), []);

  // ------------------------------Functions End-------------------------- //

  return (
    <Div
      padding="20px 24px 20px 24px "
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px rgba(0, 0, 0, 0.25) solid"
    >
      <Div display="flex">
        {data.img_url === null ? (
          <Avatar width="40px" height="40px" border="1px solid #FFFFFF">
            <AvatarLetter top="12px" left="12px">
              {data.ProviderName.charAt(0).toUpperCase()}
            </AvatarLetter>
          </Avatar>
        ) : (
          <AvatarImage
            src={File_URL + data.img_url}
            alt="image-failed"
            loading="lazy"
            width="40px"
            height="40px"
            border="1px solid #FFFFFF"
          />
        )}
        <Div marginLeft="6px">
          <Text
            color="#0a1629"
            lineHeight="22px"
            fontSize="16px"
            fontWeight="600"
          >
            {data.ProviderName}
          </Text>
          <Text
            color={status === "Online" ? "#0EAA5F" : "rgba(46, 46, 46, 0.50)"}
            fontSize="14px"
            textAlign="unset"
            fontWeight="400"
            mt="4px"
          >
            {status}
          </Text>
        </Div>
      </Div>
      <Div
        display="flex"
        textAlign="right"
        border="none !important"
        borderRadius="0px"
        background="#f4f4f4"
        alignItems="center"
        width={togg ? "50%" : "auto"}
      >
        <Div
          // display={togg ? "flex" : "none"}
          justifyContent="space-between"
          background="#FFF"
          alignItems="center"
          width="100%"
          display="flex"
          borderRadius="14px"
          backgroundColor={togg ? "#FFF" : "#F4F4F4"}
        >
          <IoIosArrowUp
            style={{
              margin: "0px 16px",
              cursor: "pointer",
              display: togg ? "" : "none",
            }}
            onClick={handleUpward}
          />
          <IoIosArrowDown
            style={{
              cursor: "pointer",
              display: togg ? "" : "none",
            }}
            onClick={handleDownward}
          />
          <InputBox
            placeholder="search"
            style={{
              border: "none",
              borderRadius: "14px",
              background: "#FFF",
              width: "50%",
              display: togg ? "" : "none",
            }}
            value={searchText}
            onChange={(e) => handleTextSearch(e)}
          />
          <Button
            border="none !important"
            color="#000000"
            padding="9px 11px"
            cursor="pointer"
            background={togg ? "#FFF" : "#f4f4f4"}
            borderRadius="14px"
            onClick={handleToggle}
          >
            <AiOutlineSearch
              font-size="22px"
              fill="#666666"
              font-weight="100"
              viewBox="0 -200 1024 1024"
            />
          </Button>
        </Div>

        <Button
          border="none !important"
          background="#f4f4f4"
          padding="6px 11px 0px 11px"
          cursor="pointer"
        >
          <Image src={mobilecall} height="22px" width="22px"></Image>
        </Button>
        <Button
          border="none !important"
          background="#f4f4f4"
          padding="6px 0px 0px 11px"
          cursor="pointer"
        >
          <Image src={callVideo} height="22px" width="22px"></Image>
        </Button>
      </Div>
    </Div>
  );
};

export default HeadNav;
