import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetPassRes } from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Text,
} from "../../StyledComponents";
import { Title, Input } from "../Styles";

import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";

import password_icon from "../../../Assets/images/password_setup_icon.png";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";

const SetupPassword = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { state } = useLocation();

  const [PasswordShow1, setPasswordShow1] = useState(true);
  const [PasswordShow, setPasswordShow] = useState(true);
  const [passErr, setPassErr] = useState(false);
  const [passErr2, setPassErr2] = useState(false);
  const [misMatch, setMissMatch] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [errMsg2, setErrMsg2] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  // const backurl =
  //   localStorage.getItem("File_URL") &&
  //   Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  // const File_URL = backurl + "/documenting/";
  const File_URL =
  DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/rpm_logo.png";
  const text = useSelector((state) => state.PreLogin.logo);
  const [email] = useState(state ? state.type.email : "");
  const passRes = useSelector((state) => state.PreLogin.passWordRes);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const Password = (e) => {
    setPwd1(e.target.value);
    setMissMatch(false);
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setPassErr(false);
    } else {
      setPassErr(true);
      setErrMsg(
        "passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const ConformPassword = (e) => {
    setPwd2(e.target.value);
    setErrMsg2("");
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    if (strongRegex.test(e.target.value)) {
      setMissMatch(false);
      setErrMsg("");
    } else {
      setMissMatch(true);
      setErrMsg(
        "passWord must contain Atleast  1 upperlower 1number 1 symbol and minimum 8 characters length "
      );
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    let err = 0;
    if (passErr || misMatch) {
      return false;
    }
    if (pwd1 === "") {
      setMissMatch(false);
      setPassErr(true);
      setErrMsg("Please enter the password");
      return false;
    }

    if (pwd2 === "") {
      setMissMatch(false);
      setPassErr2(true);
      setErrMsg2("Please enter the confirm password");
      return false;
    }

    if (pwd1 !== pwd2) {
      setMissMatch(true);
      setErrMsg("passWord Mismatch");
    }

    if (pwd1 === pwd2 && err === 0) {
      dispatch(
        GetPassRes({
          type: { new_password: pwd1, con_password: pwd2, email: email },
        })
      );
    } else {
      setMissMatch(true);
      setErrMsg("passWord Mismatch");
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (Object.keys(passRes).length > 0) {
      if (passRes === "Success") {
        Navigate("/Login");
      } else if (passRes === "Failure") {
        setMissMatch(true);
        setErrMsg("PassWord saving failed");
      } else {
        setMissMatch(true);
        setErrMsg(passRes);
      }
    }
  }, [passRes]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF" overflowY="auto">
        <Div width="500px" textAlign="center" margin="0px auto">
          {text.length !== 0 && (
            <Div textAlign="center" marginTop="72px" marginBottom="29px">
            <Image
              loading="lazy"
              src={File_URL}
              height="71px"
              width="173px"
              objectFit="contain"
            />
          </Div>
          )}
          <Image
            src={password_icon}
            alt="Setup password"
            width="124px"
            height="124px"
            display="block"
            margin="auto"
            marginTop="10%"
            marginBottom="32px"
            textAlign="center"
          />
          <Title
            color="#000000"
            fontWeight="700"
            fontSize="36px"
            lineHeight="44px"
            marginBottom="12px"
          >
            Set Your Password
          </Title>
          <Text
            alignItems="center"
            color=" #667085"
            marginBottom="32px"
            fontSize="20px"
            lineHeight="27px"
          >
            Use the email your practitioner has on file to sign in—no password
            necessary.
          </Text>
          <FormGroup marginBottom="32px" display="grid" width="100%">
            <Button
              cursor="not-allowed"
              background="#F9F8F8"
              height="48px"
              color="#667085"
              border="none"
              fontSize="20px"
              lineHeight="30px"
            >
              <FaRegUser style={{ marginRight: 8 }} /> {email}
            </Button>
          </FormGroup>
          <Form>
            <FormGroup
              marginBottom="19px"
              display="grid"
              width="100%"
              position="relative"
            >
              <FormLabel
                marginBottom="6px"
                textAlign="left"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Password
              </FormLabel>
              <Input
                placeholder="Enter password"
                type={PasswordShow ? "password" : "text"}
                value={pwd1}
                onChange={(e) => Password(e)}
              />
              {PasswordShow ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow((Prevstate) => !Prevstate)}
                />
              )}
              {passErr && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  fontFamily="inter"
                  lineHeight="24px"
                  marginTop="5px"
                >
                  {errMsg}
                </FormLabel>
              )}
            </FormGroup>
            <FormGroup
              marginBottom="19px"
              display="grid"
              width="100%"
              position="relative"
            >
              <FormLabel
                marginBottom="6px"
                textAlign="left"
                color="#7D8592"
                fontWeight="600"
                fontSize="14px"
                lineHeight="24px"
                letterSpacing="1px"
              >
                Confirm Password
              </FormLabel>
              <Input
                placeholder="Re-enter password"
                type={PasswordShow1 ? "password" : "text"}
                value={pwd2}
                onChange={(e) => ConformPassword(e)}
              />
              {PasswordShow1 ? (
                <FaRegEye
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              ) : (
                <FaRegEyeSlash
                  style={{
                    position: "absolute",
                    right: 15,
                    top: "43px",
                    cursor: "pointer",
                    color: "#7d8592",
                    fontSize: 20,
                  }}
                  onClick={() => setPasswordShow1((Prevstate) => !Prevstate)}
                />
              )}
              {misMatch && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  fontFamily="inter"
                  lineHeight="24px"
                  marginTop="5px"
                >
                  {errMsg}
                </FormLabel>
              )}

              {passErr2 && (
                <FormLabel
                  marginBottom="0px"
                  textAlign="left"
                  fontSize="14px"
                  color="red"
                  fontWeight="400"
                  fontFamily="inter"
                  lineHeight="24px"
                  marginTop="5px"
                >
                  {errMsg2}
                </FormLabel>
              )}
            </FormGroup>

            <FormGroup marginBottom="32px" display="grid" width="100%">
              <Button
                background="#2C7BE5"
                padding="13px 140px 13px 140px"
                borderRadius="8px"
                border="none"
                color="#FFF"
                onClick={(e) => handleSave(e)}
                cursor="pointer"
                className="save_active"
                hoverBackColor="#005FB2"
              >
                Save password
              </Button>
            </FormGroup>
          </Form>
        </Div>
      </Div>
    </Div>
  );
};

export default SetupPassword;
