import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetReEmailRes,
  setReEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";

import {
  Button,
  Image,
  Form,
  FormGroup,
  FormLabel,
  Div,
  Text,
  Span,
  Anchor,
} from "../../StyledComponents";
import { Input, Title } from "../Styles";
import LoginLeft from "../LoginLeft";

import ResetImage from "../../../Assets/images/reset.png";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";

const ForgetPassword = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setemail] = useState("");
  const [mailErr, setMailErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [disabledsave, setdisabledsave] = useState(false);

  // const backurl =
  //   localStorage.getItem("File_URL") &&
  //   Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  // const File_URL = backurl + "/documenting/";
  const File_URL =
  DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/rpm_logo.png";
  const emailResponse = useSelector((state) => state.PreLogin.ReEmailResp);
  const text = useSelector((state) => state.PreLogin.logo);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//

  const HandleDispatch = (e) => {
    e.preventDefault();
    setdisabledsave(true);

    if (email !== "") {
      dispatch(GetReEmailRes({ type: { email: email } }));
    } else {
      setMailErr(true);
      setErrMsg("Please enter the Email address");
      setdisabledsave(false);
    }
  };

  const HandleNavigate = (e) => {
    e.preventDefault();
    Navigate("/Login");
  };

  const EmailCheck = (e) => {
    setemail(e.target.value);
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (regex.test(e.target.value)) {
      setMailErr(false);
    } else {
      setMailErr(true);
      setErrMsg("Invalid Email");
    }
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //

  useEffect(() => {
    if (emailResponse.status === "Success") {
      setdisabledsave(false);
      Navigate("/ReVerifyAccount", {
        state: { type: { email }, reset: true },
      });
    } else if (emailResponse.status === "Failure") {
      setdisabledsave(false);
      setMailErr(true);
      if (emailResponse.output === "Unauthorised") {
        setErrMsg("Access Restricted Contact Service Provider");
      } else {
        setErrMsg("Please enter Registered email");
      }
      setTimeout(() => {
        dispatch(setReEmailRes({ status: "", output: "" }));
      }, 1500);
    }
  }, [emailResponse]);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF">
        <Div width="100%" display="flex">
          <Div width="475px" textAlign="center" margin="auto">
            {text.length !== 0 && (
               <Div textAlign="center" marginTop="72px" marginBottom="29px">
               <Image
                 loading="lazy"
                 src={File_URL}
                 height="71px"
                 width="173px"
                 objectFit="contain"
               />
             </Div>
            )}
            <Image
              src={ResetImage}
              alt="Email Verified"
              width="124px"
              height="124px"
              display="block"
              margin="auto"
              textAlign="center"
              marginBottom="32px"
              marginTop="10%"
            ></Image>
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="32px"
              lineHeight="44px"
              marginBottom="12px"
            >
              Forget Password
            </Title>
            <Text
              fontSize="20px"
              lineHeight="27px"
              alignItems="center"
              color=" #667085"
              marginBottom="32px"
            >
              Provide your email &amp; we’ll be sending you a verification code
              to reset your password.
            </Text>
            <Form>
              <FormGroup marginBottom="41px" display="grid" width="100%">
                <FormLabel
                  marginBottom="6px"
                  textAlign="left"
                  fontSize="14px"
                  color="#7D8592"
                  fontWeight="600"
                  lineHeight="24px"
                >
                  Email ID
                </FormLabel>
                <Input
                  color="#7D8592"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => EmailCheck(e)}
                />
                {mailErr && (
                  <FormLabel
                    marginBottom="0px"
                    textAlign="left"
                    fontSize="14px"
                    color="red"
                    fontWeight="400"
                    lineHeight="24px"
                    marginTop="5px"
                    fontFamily="inter"
                  >
                    {errMsg}
                  </FormLabel>
                )}
              </FormGroup>
              <FormGroup marginBottom="24px" display="grid" width="100%">
                <Button
                  className="save_active"
                  background="#2C7BE5"
                  padding="13px 0px "
                  borderRadius="8px"
                  border="none"
                  color="#FFF"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  onClick={(e) => HandleDispatch(e)}
                  cursor="pointer"
                  hoverBackColor="#005FB2"
                  disabled={disabledsave}
                >
                  Send Verification Code
                </Button>
              </FormGroup>
              <Div display="flex" justifyContent="center" marginBottom="20px">
                <Span>Back to</Span>
                <Anchor
                  color="#1F28EB"
                  borderBottom="1px solid #1F28EB"
                  margin="0px 0px 0px 10px"
                  fontFamily="inter, sans serif"
                  fontSize="14px"
                  fontWeight="400"
                  cursor="pointer"
                  onClick={(e) => HandleNavigate(e)}
                >
                  Login
                </Anchor>
              </Div>
            </Form>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default ForgetPassword;
