import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
// import {
//   GetReEmailRes,
//   setReEmailRes,
// } from "../../../StateManagement/Reducers/PreLoginState";

import LoginLeft from "../LoginLeft";

import checkEmail from "../../../Assets/images/email_check_icon.png";

import {
  Button,
  Span,
  Form,
  FormGroup,
  FormLabel,
  Image,
  Div,
  Text,
} from "../../StyledComponents";
import { Input, Title } from "../Styles";
import { FormGroupInline } from "./Styles";
import {
  GetEmailRes,
  setEmailRes,
} from "../../../StateManagement/Reducers/PreLoginState";
import DBHost from "../../../Config/DBHost";

const InstantLink = () => {
  // ------------------------------ State and Variables Start-----------------------------------------------------//
  const { state } = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const [email] = useState(state ? state.type.email : "");
  const [otpErr, setOtpErr] = useState(false);
  const [Otp, setOtp] = useState(new Array(4).fill(""));
  // const backurl =
  //   localStorage.getItem("File_URL") &&
  //   Decrypt_Value(localStorage.getItem("File_URL"), "rpmportal");
  // const File_URL = backurl + "/documenting/";
  const File_URL =
  DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/rpm_logo.png";
  const otp =
    localStorage.getItem("OTP") &&
    Decrypt_Value(localStorage.getItem("OTP"), "rpmportal");

  let mode = useSelector((state) => state.PreLogin.mode);
  const text = useSelector((state) => state.PreLogin.logo);
  // ------------------------------ State and Variables End-----------------------------------------------------//

  // ------------------------------ Functions Start-------------------------------------------------------------//
  const handleVerify = (e) => {
    e.preventDefault();

    let isEmptyObj = Object.keys(mode).length;
    if (isEmptyObj === 0) {
      mode = "old";
    }

    if (parseInt(otp) === parseInt(Otp.join(""))) {
      if (mode === "old") {
        Navigate("/SetupPassword", { state: { type: { email } } });
      } else if (mode === "new") {
        Navigate("/SignUp", { state: { type: { email } } });
      }
      localStorage.removeItem("OTP");
    } else {
      setOtpErr(true);
      // localStorage.removeItem("OTP");
    }
  };

  const handleOtp = (e, i) => {
    setOtpErr(false);
    if (isNaN(e.value)) return;
    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };

  const ResendOtp = () => {
    dispatch(GetEmailRes({ type: { email: email }, mode: mode }));
  };
  // ------------------------------ Functions End------------------------------------------------------------- //
  //-----------------------------UseEffect Start---------------------------------------------- //
  useEffect(() => {
    dispatch(setEmailRes({ status: "", output: "" }));
  }, []);
  //-----------------------------UseEffect End---------------------------------------------- //
  return (
    <Div display="flex" height="100vh" minWidth="1024px">
      <Div width="50%" backgroundColor="#F8FAFB" position="relative">
        <LoginLeft />
      </Div>
      <Div width="50%" backgroundColor="#FFFFFF">
        <Div width="100%">
          <Div width="500px" textAlign="center" margin="auto">
            {text.length !== 0 && (
              <Div textAlign="center" marginTop="72px" marginBottom="29px">
              <Image
                loading="lazy"
                src={File_URL}
                height="71px"
                width="173px"
                objectFit="contain"
              />
            </Div>
            )}
            <Image
              src={checkEmail}
              alt="Email Verified"
              width="124px"
              height="124px"
              display="block"
              margin="auto"
              marginBottom="35px"
              marginTop="10%"
              textAlign="center"
            ></Image>
            <Title
              color="#000000"
              fontWeight="800"
              fontSize="36px"
              lineHeight="44px"
              marginBottom="18px"
            >
              Verify your Account
            </Title>
            <Text
              color=" #667085"
              alignItems="center"
              fontSize="20px"
              lineHeight="30px"
              marginBottom="40px"
            >
              Check you email, we just sent you a verification link to &nbsp;
              <Span color="#1F28EB" fontWeight="600">
                {email}
              </Span>
            </Text>
            <Form>
              <FormGroupInline>
                {Otp.map((row, i) => {
                  return (
                    <FormGroup
                      marginBottom="24px"
                      display="inline-grid"
                      width="25%"
                      key={i}
                    >
                      <Input
                        key={i}
                        width="80px"
                        height="80px"
                        fontSize="36px"
                        lineHeight="44px"
                        fontWeight="700"
                        fontFamily="'Urbanist'"
                        maxLength="1"
                        textAlign="center"
                        maxlength="1"
                        value={row}
                        onChange={(e) => handleOtp(e.target, i)}
                        onFocus={(e) => e.target.select()}
                        onKeyUp={(e) => KeyDown(e, i)}
                        onPaste={(e) => FieldPaste(e, i)}
                      />
                    </FormGroup>
                  );
                })}
                {otpErr && (
                  <FormGroup
                    marginBottom="24px"
                    display="inline-grid"
                    width="100%"
                    left="150%"
                    gridColumn="1 / span 4"
                  >
                    <FormLabel
                      marginBottom="6px"
                      textAlign="center"
                      fontSize="14px"
                      color="red"
                      fontWeight="400"
                      lineHeight="24px"
                      fontFamily="inter"
                    >
                      otp is inValid
                    </FormLabel>
                  </FormGroup>
                )}
              </FormGroupInline>
              <FormGroup marginBottom="32px" display="grid" width="100%">
                <Button
                  background="#2C7BE5"
                  padding="13px 140px 13px 140px"
                  borderRadius="8px"
                  border="none"
                  color="#FFF"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  letterSpacing="1.1px"
                  onClick={(e) => handleVerify(e)}
                  cursor="pointer"
                  className="save_active"
                  hoverBackColor="#005FB2"
                >
                  Verify Account
                </Button>
              </FormGroup>
            </Form>
            <FormGroup
              textAlign="center"
              marginBottom="63px"
              display="inline-block"
            >
              Don’t receive verification code?
              <Span
                cursor="pointer"
                fontFamily="Urbanist, sans-serif"
                fontWeight="400"
                fontSize="18px"
                lineHeight="26px"
                marginLeft="10px"
                color="#1f28eb"
                onClick={() => ResendOtp()}
              >
                Resend Code
              </Span>
            </FormGroup>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default InstantLink;
