import React from "react";
import moment from "moment";

import { Div, Text } from "../../StyledComponents";
import {
  SearchProvider,
  TnumbComponent,
  Avatar,
  AvatarLetter,
  AvatarImage,
} from "../StyledComponents";
import { UserContainer, UserContent, ChatDiv, ChatText, Div1 } from "./Styles";
import { getUnreadCount } from "../../../StateManagement/Reducers/MessengerState";
import { useDispatch } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import DBHost from "../../../Config/DBHost";

const ProviderList = ({
  list,
  OnChangeSearch,
  sessioVal,
  handleClick,
  search,
}) => {
  // ------------------------------ State And Variables Start------------------------ //
  const dispatch = useDispatch();
  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");
  const File_URL = DBHost.finalurl + "/sites/" + DBHost.DBHost + "/images/";

  // ------------------------------State And Variables End------------------------ //
  // ------------------------------ Function Start------------------------ //
  const makeItAsMinutes = (timer) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    const formattedDuration = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    return formattedDuration;
  };

  const renderFormattedDate = (item) => {
    const itemDate = moment(item);
    const currentDate = moment();

    if (itemDate.isSame(currentDate, "day")) {
      return itemDate.format("hh:mm a");
    }

    if (itemDate.isSame(currentDate.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    }

    return itemDate.format("DD/MM/YYYY");
  };
  // ------------------------------ Function End------------------------ //

  return (
    <Div1
      width="37%"
      height="100%"
      padding="0px 32px 0px 0px"
      background="#ffffff"
      className="providerlistres"
    >
      <Text
        fontWeight="500"
        fontSize="20px"
        lineHeight="20px"
        color="#000000"
        margin="4px 0px 35px 24px"
      >
        Chats
      </Text>

      <Div display="flex" width="100%" marginLeft="24px">
        <SearchProvider searchVal={OnChangeSearch} />
      </Div>
      <UserContainer>
        {list.length > 0 ? (
          list
            .filter(
              (data) =>
                data.ProviderName?.toLowerCase().indexOf(search.toLowerCase()) >
                -1
            )
            .map((item, index) => (
              <UserContent
                active={index === sessioVal}
                onClick={() => {
                  let data = { Pid: Pat_ID, Provider_Id: item.id };
                  dispatch(getUnreadCount(data));
                  handleClick(index, item);
                }}
                key={index}
              >
                {item.img_url === null ? (
                  <Avatar
                    minWidth="40px"
                    width="40px"
                    height="40px"
                    border="1px solid #ffffff"
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                  >
                    <AvatarLetter
                    //  top="11px" left="12px"
                    >
                      {item.ProviderName.charAt(0).toUpperCase()}
                    </AvatarLetter>
                  </Avatar>
                ) : (
                  <AvatarImage
                    src={File_URL + item.img_url}
                    alt="image-failed"
                    loading="lazy"
                    width="40px"
                    height="40px"
                    border="1px solid #ffffff"
                    minWidth="40px"
                  />
                )}

                <ChatDiv backgroundColor="unset" className="providerwidth">
                  <Text
                    color="#0a1629"
                    mb="4px"
                    lineHeight="22px"
                    fontSize="16px"
                    fontWeight="600"
                    textAlign="unset"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    id={`msg_cht-p_prvd${index}`}
                  >
                    {item.ProviderName}
                  </Text>
                  <Text
                    color="#91929e"
                    lineHeight="18px"
                    fontSize="13px"
                    fontWeight="400"
                    textAlign="unset"
                    whiteSpace="nowrap"
                    marginTop="4px"
                    width="130px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {item.type === "image" ? (
                      <TnumbComponent message={item.message} variant="image" />
                    ) : item.type === "doc" ? (
                      <TnumbComponent message={item.message} variant="doc" />
                    ) : item.type === "audio" ? (
                      <TnumbComponent
                        message={makeItAsMinutes(item.Voicenote_duration)}
                        variant="audio"
                      />
                    ) : (
                      item.message
                    )}
                  </Text>
                </ChatDiv>
                <ChatDiv
                  className="datewidth"
                  display="flex"
                  alignItems="end"
                  backgroundColor="unset"
                  marginLeft="auto"
                  flexDirection="column"
                  textAlign="end"
                >
                  <ChatText
                    fontWeight="400"
                    lineHeight="16px"
                    color="#7d8592"
                    className="datetimemessage"
                    width="100%"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {item.date && renderFormattedDate(item.date)}
                    {/* {item.date} */}
                  </ChatText>
                  {item.unreadCount !== "0" && (
                    <Div
                      height="18px"
                      width="18px"
                      backgroundColor="#f65160"
                      borderRadius="50%"
                      marginTop="6px"
                      marginLeft="auto"
                      padding="3px"
                      zIndex="100"
                      // left="782px"
                    >
                      <Text
                        fontFamily="Nunito Sans"
                        fontWeight="600"
                        fontSize="12px"
                        lineHeight="12px"
                        color="#ffffff"
                        textAlign="center"
                      >
                        {item.unreadCount}
                      </Text>
                    </Div>
                  )}
                </ChatDiv>
              </UserContent>
            ))
        ) : (
          <Div padding="30%">
            <Text
              fontWeight="400"
              fontSize="16px"
              lineHeight="16px"
              color="#000"
            >
              No Providers to Chat
            </Text>
          </Div>
        )}
      </UserContainer>
    </Div1>
  );
};

export default ProviderList;
