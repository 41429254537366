import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Welcome from "../Modules/Login/Welcome";
import SignUp from "../Modules/Login/SignUp";
import SetupPassword from "../Modules/Login/SetupPassword";
import InstantSignIn from "../Modules/Login/InstantSignIn";
import VerifyAccount from "../Modules/Login/VerifyAccount";
import ResetPassword from "../Modules/Login/ResetPassword";
import ForgetPassword from "../Modules/Login/ForgetPassword";
import ResetVerify from "../Modules/Login/ResetVerify";
import Login from "../Modules/Login";
import LeftNav from "../Modules/LeftNav";
import DashBoard from "../Modules/DashBoard";
import Vitals from "../Modules/Vitals";
import Document from "../Modules/Document";
import Messenger from "../Modules/Message";
import Settings from "../Modules/Settings";
import AccessDenied from "../Modules/Login/AccessDenied";
import useAutoLogout from "./useAutoLogout";
import { MainContainer, MainContainerLeft, MainContainerRight } from "./styles";
import { Decrypt_Value } from "../MiddleWare/EncryptDecrypt";
import axiosInst from "../Config";

const Router = () => {
  const SingPatDetails = useSelector((state) => state.Dashboard.SinglePatient);
  let idle =
    localStorage.getItem("idle") &&
    Decrypt_Value(localStorage.getItem("idle"), "rpmportal");

    const idleinMin = parseInt(idle) * 60;
  let timer = useAutoLogout(parseInt(idleinMin));

  const Pat_ID =
    localStorage.getItem("PID") &&
    Decrypt_Value(localStorage.getItem("PID"), "rpmportal");

  const CurrTime = moment().format("YYYY-MM-DD HH:mm A");
  const token_exp_time = moment(
    localStorage.getItem("token_exp")
      ? Decrypt_Value(localStorage.getItem("token_exp"), "rpmportal")
      : ""
  ).format("YYYY-MM-DD HH:mm A");

  if (
    SingPatDetails.enrollment_status === "4" ||
    timer === 0 ||
    token_exp_time < CurrTime
  ) {
    const data = {
      pid: Pat_ID,
    };
    axiosInst
      .post(`/rpmportal/updatePortalLogout`, data)
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        localStorage.setItem("timeout", true);
      })
      .catch((e) => {
        console.log(e);
        localStorage.clear();
        window.location.href = "/";
        localStorage.setItem("timeout", true);
      });
  }
  if (!Pat_ID)
    return (
      <>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/SignUp" element={<SignUp />} />
          <Route exact path="/SetupPassword" element={<SetupPassword />} />
          <Route exact path="/InstantSignIn" element={<InstantSignIn />} />
          <Route exact path="/VerifyAccount" element={<VerifyAccount />} />
          <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
          <Route exact path="/ReVerifyAccount" element={<ResetVerify />} />
          <Route exact path="/ReSetupPassword" element={<ResetPassword />} />
          <Route exact path="/AccessDenied" element={<AccessDenied />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </>
    );
  else if (Pat_ID === null)
    return (
      <Routes>
        <Route exact path="/" element={<AccessDenied />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  else if (Pat_ID)
    return (
      <>
        <MainContainer>
          <MainContainerLeft>
            <LeftNav />
          </MainContainerLeft>
          <MainContainerRight>
            <Routes>
              <Route exact path="/dashboard" element={<DashBoard />} />
              <Route exact path="/" element={<DashBoard />} />
              <Route exact path="/vitals" element={<Vitals />} />
              <Route exact path="/messenger" element={<Messenger />} />
              <Route exact path="/documents" element={<Document />} />
              <Route
                exact
                path="/settings/editProfile"
                element={<Settings />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </MainContainerRight>
        </MainContainer>
      </>
    );
};

export default Router;
